<template>
  <div class="container">
    <div class="main">
      <top-timer></top-timer>
      <!-- <div class="dohand">
        <div class="preset">预设</div>
        <div class="amount">金额<input type="text" /></div>
        <div class="btn none">确认</div>
        <div class="btn yes">重置</div>
        <div class="btn2 a">结果走势</div>
   
      </div> -->
      <div class="player listmain type-ssg lgklsf game-lgklsf game-ssg-zp">
        <div code="lst" class="data clst">
          <h3>总和、龙虎</h3>
          <ul>
            <li balls="" itid="24622" code="sum|dx_d" id="sum|dx_d_24622" class="">
              <ol style="width:44px" class="td_name">总和大</ol>
              <ol id="rate_24622" class="td_rate">1.9898</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24622" id="cash_24622" type="text"></ol>
            </li>
            <li balls="" itid="24620" code="sum|ds_d" id="sum|ds_d_24620" class="">
              <ol style="width:44px" class="td_name">总和单</ol>
              <ol id="rate_24620" class="td_rate">1.9898</ol>
              <ol class="td_cash lianTip"><input once_max="0" name="cash_24620" id="cash_24620" type="text"><b>+7</b></ol>
            </li>
            <li balls="" itid="57439" code="sum|wsdx_d" id="sum|wsdx_d_57439" class="">
              <ol style="width:44px" class="td_name">总尾大</ol>
              <ol id="rate_57439" class="td_rate">1.9898</ol>
              <ol class="td_cash"><input once_max="0" name="cash_57439" id="cash_57439" type="text"></ol>
            </li>
            <li balls="lh_l" itid="24648" code="lh|Z1-Z8" id="lh|Z1-Z8_24648" class="">
              <ol style="width:44px" class="td_name">龙1</ol>
              <ol id="rate_24648" class="td_rate">1.9898</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24648" id="cash_24648" type="text"></ol>
            </li>

            <li balls="" itid="24623" code="sum|dx_x" id="sum|dx_x_24623">
              <ol style="width:44px" class="td_name">总和小</ol>
              <ol id="rate_24623" class="td_rate">1.9898</ol>
              <ol class="td_cash lianTip"><input once_max="0" name="cash_24623" id="cash_24623" type="text"></ol>
            </li>
            <li balls="" itid="24621" code="sum|ds_s" id="sum|ds_s_24621">
              <ol style="width:44px" class="td_name">总和双</ol>
              <ol id="rate_24621" class="td_rate">1.9898</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24621" id="cash_24621" type="text"></ol>
            </li>
            <li balls="" itid="57440" code="sum|wsdx_x" id="sum|wsdx_x_57440">
              <ol style="width:44px" class="td_name">总尾小</ol>
              <ol id="rate_57440" class="td_rate">1.9898</ol>
              <ol class="td_cash"><input once_max="0" name="cash_57440" id="cash_57440" type="text"></ol>
            </li>
            <li balls="lh_h" itid="24649" code="lh|Z1-Z8" id="lh|Z1-Z8_24649">
              <ol style="width:44px" class="td_name">虎8</ol>
              <ol id="rate_24649" class="td_rate">1.9898</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24649" id="cash_24649" type="text"></ol>
            </li>
          </ul>
        </div>
        <!-- 第一球 -->
        <div code="Z1" :class="'data cZ'+i" v-for="i in 8" :key="i">
          <h3>第{{i}}球</h3>
          <ul>
            <li balls="ds_d" itid="24460" code="Z1" id="Z1_24460" class="">
              <ol style="width:44px" class="td_name">单</ol>
              <ol id="rate_24460" class="td_rate">1.9898</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24460" id="cash_24460" type="text"></ol>
            </li>
            <li balls="ds_s" itid="24468" code="Z1" id="Z1_24468">
              <ol style="width:44px" class="td_name">双</ol>
              <ol id="rate_24468" class="td_rate">1.9898</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24468" id="cash_24468" type="text"></ol>
            </li>
            <li balls="dx_d" itid="24476" code="Z1" id="Z1_24476">
              <ol style="width:44px" class="td_name">大</ol>
              <ol id="rate_24476" class="td_rate">1.9898</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24476" id="cash_24476" type="text"></ol>
            </li>
            <li balls="dx_x" itid="24484" code="Z1" id="Z1_24484">
              <ol style="width:44px" class="td_name">小</ol>
              <ol id="rate_24484" class="td_rate">1.9898</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24484" id="cash_24484" type="text"></ol>
            </li>
            <li balls="wsdx_d" itid="24508" code="Z1" id="Z1_24508">
              <ol style="width:44px" class="td_name">尾大</ol>
              <ol id="rate_24508" class="td_rate">1.9898</ol>
              <ol class="td_cash lianTip"><input once_max="0" name="cash_24508" id="cash_24508" type="text"><b>+3</b></ol>
            </li>
            <li balls="wsdx_x" itid="24516" code="Z1" id="Z1_24516">
              <ol style="width:44px" class="td_name">尾小</ol>
              <ol id="rate_24516" class="td_rate">1.9898</ol>
              <ol class="td_cash lianTip"><input once_max="0" name="cash_24516" id="cash_24516" type="text"></ol>
            </li>
            <li balls="hds_d" itid="24524" code="Z1" id="Z1_24524">
              <ol style="width:44px" class="td_name">合数单</ol>
              <ol id="rate_24524" class="td_rate">1.9898</ol>
              <ol class="td_cash lianTip"><input once_max="0" name="cash_24524" id="cash_24524" type="text"><b>+4</b></ol>
            </li>
            <li balls="hds_s" itid="24532" code="Z1" id="Z1_24532">
              <ol style="width:44px" class="td_name">合数双</ol>
              <ol id="rate_24532" class="td_rate">1.9898</ol>
              <ol class="td_cash lianTip"><input once_max="0" name="cash_24532" id="cash_24532" type="text"></ol>
            </li>
          </ul>
        </div>
        <!-- ----------- -->

      </div>
      <yushe :disabled="isDisabled" />
      <div class="roadmap">
        <table class="thlottab">
          <tbody>
            <tr>
              <td class="selected" data-code="1" name="Z1">第一球</td>
              <td data-code="2" name="Z2">第二球</td>
              <td data-code="3" name="Z3">第三球</td>
              <td data-code="4" name="Z4">第四球</td>
              <td data-code="5" name="Z5">第五球</td>
              <td data-code="6" name="Z6">第六球</td>
              <td data-code="7" name="Z7">第七球</td>
              <td data-code="8" name="Z8">第八球</td>
            </tr>
          </tbody>
        </table>
        <table class="ltbinfo">
          <tbody>
            <tr>
              <th :class="tableType==1?'selected':''" @click="setTableType(1)" data-code="ds" name="ds">单双</th>
              <th :class="tableType==2?'selected':''" @click="setTableType(2)" data-code="dx" name="dx">大小</th>
              <th :class="tableType==3?'selected':''" @click="setTableType(3)" data-code="sum" name="sum">总和</th>
              <th :class="tableType==4?'selected':''" @click="setTableType(4)" data-code="sum_ds" name="sum_ds">总和单双</th>
              <th :class="tableType==5?'selected':''" @click="setTableType(5)" data-code="sum_dx" name="sum_dx">总和大小</th>
              <th :class="tableType==6?'selected':''" @click="setTableType(6)" data-code="hds" name="hds">合单双</th>
              <th :class="tableType==7?'selected':''" @click="setTableType(7)" data-code="ws_dx" name="ws_dx">大小尾</th>
              <th :class="tableType==8?'selected':''" @click="setTableType(8)" data-code="sebo" name="sebo">色波</th>
              <th :class="tableType==9?'selected':''" @click="setTableType(9)" data-code="lh_b1-b8" name="lh_b1-b8">龙虎</th>
              <th :class="tableType==10?'selected':''" @click="setTableType(10)" data-code="siji" name="siji">四季</th>
            </tr>
          </tbody>
        </table>
        <!-- 单双 -->
        <table v-if="tableType==1" colspan="0" id="lt_ds" cellspan="0" class="lttab" style="display: table;">
          <tbody>
            <tr>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
            </tr>
            <tr>
              <td>　</td>
              <td>　</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td>　</td>
              <td>　</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td>　</td>
              <td>　</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td class="ds_s">双</td>
              <td>　</td>
              <td>　</td>
              <td class="ds_d">单</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="ds_s">双</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="ds_s">双</td>
              <td class="ds_d">单</td>
              <td>　</td>
              <td class="ds_d">单</td>
              <td>　</td>
              <td class="ds_d">单</td>
              <td>　</td>
            </tr>

          </tbody>
        </table>
        <!-- 大小 -->
        <table v-if="tableType==2" colspan="0" id="lt_dx" cellspan="0" class="lttab" style="display: table;">
          <tbody>
            <tr>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
            </tr>
            <tr>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="dx_d">大</td>
              <td>　</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td>　</td>
              <td>　</td>
              <td class="dx_x">小</td>
              <td>　</td>
              <td class="dx_x">小</td>
              <td>　</td>
              <td class="dx_x">小</td>
              <td>　</td>
              <td class="dx_x">小</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td>　</td>
              <td class="dx_d">大</td>
              <td>　</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td>　</td>
              <td class="dx_d">大</td>
              <td class="dx_x">小</td>
              <td class="dx_d">大</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
            </tr>

          </tbody>
        </table>
        <!-- 总和 -->
        <table v-if="tableType==3" colspan="0" id="lt_sum" cellspan="0" class="lttab" style="display: table;">
          <tbody>
            <tr>
              <td class="x">66</td>
              <td class="x">79</td>
              <td class="d">93</td>
              <td class="x">75</td>
              <td class="d">104</td>
              <td class="x">75</td>
              <td class="d">86</td>
              <td class="d">95</td>
              <td class="d">93</td>
              <td class="x">73</td>
              <td class="x">64</td>
              <td class="x">66</td>
              <td class="x">71</td>
              <td class="x">80</td>
              <td class="d">85</td>
              <td class="d">88</td>
              <td class="d">86</td>
              <td class="d">104</td>
              <td class="d">86</td>
              <td class="x">77</td>
              <td class="x">65</td>
              <td class="x">57</td>
              <td class="x">71</td>
              <td class="d">99</td>
              <td class="x">77</td>
              <td class="x">63</td>
              <td class="x">77</td>
              <td class="d">100</td>
              <td class="x">78</td>
              <td class="x">81</td>
              <td class="d">92</td>
              <td class="d">87</td>
              <td class="x">74</td>
              <td class="d">89</td>
              <td class="x">78</td>
              <td class="x">69</td>
              <td class="d">86</td>
              <td class="x">81</td>
              <td class="x">75</td>
              <td class="d">100</td>
            </tr>
            <tr>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="d">100</td>
            </tr>

          </tbody>
        </table>
        <!-- 总和单双 -->
        <table v-if="tableType==4" colspan="0" id="lt_sum_ds" cellspan="0" class="lttab" style="display: table;">
          <tbody>
            <tr>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
            </tr>
            <tr>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="sum_ds_s">双</td>
              <td>　</td>
              <td class="sum_ds_s">双</td>
              <td>　</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="sum_ds_d">单</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="sum_ds_d">单</td>
              <td class="sum_ds_s">双</td>
              <td class="sum_ds_d">单</td>
            </tr>

          </tbody>
        </table>
        <!-- 总和大小 -->
        <table v-if="tableType==5" colspan="0" id="lt_sum_dx" cellspan="0" class="lttab" style="display: table;">
          <tbody>
            <tr>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_draw">和</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
            </tr>
            <tr>
              <td>　</td>
              <td class="sum_dx_d">大</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="sum_dx_d">大</td>
              <td>　</td>
              <td>　</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td>　</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td>　</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td>　</td>
              <td class="sum_dx_x">小</td>
              <td>　</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td>　</td>
              <td>　</td>
              <td class="sum_dx_x">小</td>
              <td>　</td>
              <td class="sum_dx_x">小</td>
              <td class="sum_dx_d">大</td>
              <td class="sum_dx_x">小</td>
              <td>　</td>
            </tr>

          </tbody>
        </table>
        <!-- 合单双 -->
        <table v-if="tableType==6" colspan="0" id="lt_hds" cellspan="0" class="lttab" style="display: table;">
          <tbody>
            <tr>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
            </tr>
            <tr>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="hds_s">双</td>
              <td>　</td>
              <td>　</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td>　</td>
              <td>　</td>
              <td class="hds_d">单</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td>　</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td>　</td>
              <td>　</td>
              <td class="hds_d">单</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td>　</td>
              <td class="hds_d">单</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
              <td>　</td>
              <td>　</td>
              <td class="hds_s">双</td>
              <td>　</td>
              <td class="hds_s">双</td>
              <td class="hds_d">单</td>
            </tr>

          </tbody>
        </table>
        <!-- 大小尾 -->
        <table v-if="tableType==7" colspan="0" id="lt_ws_dx" cellspan="0" class="lttab" style="display: table;">
          <tbody>
            <tr>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
            </tr>
            <tr>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="ws_dx_x">小</td>
              <td>　</td>
              <td class="ws_dx_x">小</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="ws_dx_x">小</td>
              <td>　</td>
              <td>　</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td>　</td>
              <td class="ws_dx_d">大</td>
              <td>　</td>
              <td class="ws_dx_d">大</td>
              <td class="ws_dx_x">小</td>
              <td class="ws_dx_d">大</td>
              <td>　</td>
              <td>　</td>
              <td class="ws_dx_x">小</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="ws_dx_x">小</td>
            </tr>

          </tbody>
        </table>
        <!-- 色波 -->
        <table v-if="tableType==8" colspan="0" id="lt_sebo" cellspan="0" class="lttab" style="display: table;">
          <tbody>
            <tr>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_red">红</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_red">红</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_red">红</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_red">红</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_red">红</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_red">红</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_red">红</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_red">红</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_red">红</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_red">红</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_red">红</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_red">红</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_red">红</td>
              <td class="sebo_blue">蓝</td>
            </tr>
            <tr>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td>　</td>
              <td>　</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td>　</td>
              <td class="sebo_blue">蓝</td>
              <td>　</td>
              <td class="sebo_blue">蓝</td>
              <td>　</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td>　</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td>　</td>
              <td class="sebo_blue">蓝</td>
              <td>　</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td>　</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_blue">蓝</td>
              <td>　</td>
              <td class="sebo_blue">蓝</td>
              <td class="sebo_red">红</td>
              <td class="sebo_blue">蓝</td>
              <td>　</td>
              <td class="sebo_blue">蓝</td>
            </tr>

          </tbody>
        </table>
        <!-- 龙虎 -->
        <table v-if="tableType==9" colspan="0" id="lt_lh_b1-b8" cellspan="0" class="lttab" style="display: table;">
          <tbody>
            <tr>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
            </tr>
            <tr>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td>　</td>
              <td>　</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td>　</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_h">虎</td>
              <td>　</td>
              <td>　</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td>　</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td>　</td>
              <td>　</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td>　</td>
              <td class="lh|b1-b8_l">龙</td>
              <td class="lh|b1-b8_h">虎</td>
              <td class="lh|b1-b8_l">龙</td>
              <td>　</td>
              <td class="lh|b1-b8_l">龙</td>
              <td>　</td>
              <td>　</td>
              <td class="lh|b1-b8_h">虎</td>
            </tr>

          </tbody>
        </table>
        <!-- 四季 -->
        <table v-if="tableType==10" colspan="0" id="lt_siji" cellspan="0" class="lttab" style="display: table;">
          <tbody>
            <tr>
              <td class="siji_s2">夏</td>
              <td class="siji_s3">秋</td>
              <td class="siji_s1">春</td>
              <td class="siji_s2">夏</td>
              <td class="siji_s1">春</td>
              <td class="siji_s3">秋</td>
              <td class="siji_s4">冬</td>
              <td class="siji_s1">春</td>
              <td class="siji_s2">夏</td>
              <td class="siji_s4">冬</td>
              <td class="siji_s3">秋</td>
              <td class="siji_s1">春</td>
              <td class="siji_s2">夏</td>
              <td class="siji_s4">冬</td>
              <td class="siji_s3">秋</td>
              <td class="siji_s4">冬</td>
              <td class="siji_s2">夏</td>
              <td class="siji_s3">秋</td>
              <td class="siji_s2">夏</td>
              <td class="siji_s1">春</td>
              <td class="siji_s3">秋</td>
              <td class="siji_s2">夏</td>
              <td class="siji_s4">冬</td>
              <td class="siji_s3">秋</td>
              <td class="siji_s2">夏</td>
              <td class="siji_s1">春</td>
              <td class="siji_s2">夏</td>
              <td class="siji_s3">秋</td>
              <td class="siji_s4">冬</td>
              <td class="siji_s3">秋</td>
              <td class="siji_s1">春</td>
              <td class="siji_s3">秋</td>
              <td class="siji_s1">春</td>
              <td class="siji_s4">冬</td>
              <td class="siji_s1">春</td>
              <td class="siji_s3">秋</td>
              <td class="siji_s4">冬</td>
              <td class="siji_s3">秋</td>
              <td class="siji_s2">夏</td>
              <td class="siji_s1">春</td>
            </tr>
            <tr>
              <td class="siji_s2">夏</td>
              <td>　</td>
              <td>　</td>
              <td class="siji_s2">夏</td>
              <td class="siji_s1">春</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="siji_s4">冬</td>
              <td>　</td>
              <td>　</td>
              <td class="siji_s2">夏</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="siji_s3">秋</td>
              <td>　</td>
              <td>　</td>
              <td class="siji_s3">秋</td>
              <td>　</td>
              <td class="siji_s4">冬</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="siji_s3">秋</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="siji_s4">冬</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="siji_s3">秋</td>
              <td>　</td>
              <td>　</td>
            </tr>

          </tbody>
        </table>
        <!--  -->
      </div>
    </div>
    <div class="other">
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long">
          <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div>
          <ul class="longlist">
            <li>
              <span>1-2【龙】</span>
              <b class="blue">10期</b>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import mixins from "../mixins/mainMixins";
export default {
  name: "",
  props: [""],
  data() {
    return {
      tableType: 1,
      // 长龙 tab
      changelongActive: "1",
      // 连续开  连续未开 tab
      kaiActive: "1",
      rowsData: [
        { title: "总和、龙虎", arr: [] },
        { title: "第一球", arr: [] },
        { title: "第二球", arr: [] },
        { title: "第三球", arr: [] },
        { title: "第四球", arr: [] },
        { title: "第五球", arr: [] },
        { title: "第六球", arr: [] },
        { title: "第七球", arr: [] },
        { title: "第八球", arr: [] },
      ],
      labelArr1: [""],
    };
  },
  components: {
    yushe,
    topTimer,
  },
  watch: {},
  created() {},

  methods: {
    // 切换 不一个类型得 表格
    setTableType(num) {
      this.tableType = num;
    },
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
  },
};
</script>
<style  scoped>
.listmain div.clst ul li {
  width: 25%;
}
</style>